.header{

    .container {
        position: relative;
    }

    .header__logo {
        position: relative;
        padding: 15px 0;

        .logo{
            h1{
                margin: 0;
                font-size: 20px;
            }
        }
    }

    .header__nav{
        position: absolute;
        right: 0;    
        top: 50%;
        margin-top: -15px;
    }
    //
    // @include e("notice") {
    //     padding: 30px;
    //     background-color: #FFF;
    //     box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    //     width: 100%;
    //     border-radius: 6px;
    //     top: 30px;
    //     position: relative;
    //
    //     h2{
    //         font-weight: normal;
    //         margin: 0;
    //         color: $header-grey;
    //         margin-bottom: 5px;
    //     }
    //
    //     p{
    //         margin: 0;
    //         color: $body-grey;
    //         font-size: 15px;
    //     }
    // }

}

/// Block Element
/// @access public
/// @param {String} $element - Element's name
@mixin e($element) {
    &__#{$element} {
        @content;
    }
}

/// Block Modifier
/// @access public
/// @param {String} $modifier - Modifier's name
@mixin m($modifier) {
    &--#{$modifier} {
        @content;
    }
}

@mixin flex{
    display: js_flex;
    display: flex;
}

@for $i from 0 to 5 {
    .m-#{$i*5} {margin: #{$i * 5}px }
    .m-l-#{$i*5} {margin-left: #{$i * 5}px }
    .m-r-#{$i*5} {margin-right: #{$i * 5}px }
    .m-t-#{$i*5} {margin-top: #{$i * 5}px }
    .m-b-#{$i*5} {margin-bottom: #{$i * 5}px }
}

$boring-green: #66bb6a;
$gradient-start-tealblue: #008aa6;
$gradient-end-teal: #00a679;
$header-grey: #333333;
$description-grey: #a0a0a0;
$frame-white: #dadada;
$body-grey: #7a7a7a;
$line-white: #e9e9e9;
$pure-white: #ffffff;
$red-pink: #ed4264;

$body-gradient-start: #FFF;
$body-gradient-end: #fafafa;

$tag-background: #f2f2f2;
$tag-color: #b4b5b4;

$seperator-color: #f2f2f2;
$service-color: #696a69;

$meta-color: #8d8d8d;

$text-color: #575757;

$footer-color: #f2f2f2;
$footer-text-color: #909090;

$timeline-line-color: #efefef;
$timeline-event-time-color: #696a69;
$timeline-update-time-color: #b0b0b0;

$warning-color: #BC4343;

@import "statuspage/default";
@import "statuspage/footer";
@import "statuspage/block";
@import "statuspage/card";
@import "statuspage/list";
@import "statuspage/label";
@import "statuspage/layout1";
@import "statuspage/col";
@import "statuspage/header";
@import "statuspage/button";
@import "statuspage/service";
@import "statuspage/subscribe";
@import "statuspage/tag";
@import "statuspage/timeline";
@import "statuspage/detail-block";
@import "statuspage/error";
@import "statuspage/nav";
@import "statuspage/overall-status";
@import "statuspage/statuspage-warning-banner";

.card{
    background: #FFFFFF;
    border: 1px solid #dadada;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.1);
    border-radius: 10px;
    padding: 20px 25px;
    font-size: 18px;
    transition: height 1s;

    .card__content{
        margin-top: 25px;
    }
}

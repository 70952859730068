.statuspage-warning-banner{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(0,0,0,0.8);
    text-align: center;
    color: #FFF;
    padding: 20px;
    font-size: 14px;
    z-index: 1001;
    a{
        text-decoration: underline;
    }

    & + footer{
        margin-bottom: 58px;
    }
}

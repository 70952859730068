.overall-status{
    border-radius: 4px;
    padding: 15px;
    color: #FFF;

    &__title{
        font-size: 18px;
        i{
            width: 20px;
            text-align: center;
        }
    }
}

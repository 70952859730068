.timeline{
    padding-top: 0;
    padding-left: 150px;
    padding-bottom: 70px;
    border-left: 2px solid $timeline-line-color;
    border-width: 0;
    border-left-width: 2px;
    margin-left: 6px;
    position: relative;

    @media screen and (max-width: 480px) {
        padding-left: 15px;
        .flex{
            display: inherit;
        }
        .list__item__meta{
            margin-left: 0 !important;
        }
    }

    &:first-child{
        border-image: linear-gradient(to bottom, $body-gradient-end 10px, #efefef 10px) 1 100%;
    }

    &:last-child{
        border-image: linear-gradient(to bottom, #efefef 70%, $body-gradient-end 100%) 1 100%;
    }

    &:first-child:last-child{
        border-image: linear-gradient(to bottom, $body-gradient-end 10px, #efefef 10px, #efefef 70%, $body-gradient-end 100%) 1 100%;
    }

    &:before{
        content: ' ';
        width: 14px;
        height: 14px;
        z-index: 10;
        position: absolute;
        left: -8px;
        border-radius: 1000px;
        top: 6px;
        background-color: $boring-green;

        .events-ongoing & {
            background-color: $warning-color;
        }
    }

    & > div:first-child,
    & > div:not(:first-child) > div{
        position: relative;
    }

    @include e("event-time") {
        position: absolute;
        top: 0;
        left: -150px;
        color: $timeline-event-time-color;
        padding: 5px 18px;

        @media screen and (max-width: 480px) {
            position: inherit;
            left: inherit;
            top: inherit;
            display: block;
            padding-left: 0;
        }
    }

    @include e("update-time") {
        position: absolute;
        top: 0;
        left: -150px;
        color: $timeline-update-time-color;
        padding: 15px 18px;

        @media screen and (max-width: 480px) {
            position: inherit;
            left: inherit;
            top: inherit;
            padding: 0;
            padding-top: 10px;
            display: inline-block;
        }
    }

    @include m("detail-page") {
        padding-bottom: 0;
        border: none;
        border-image: none !important;
        margin: 0;
        &:before{
            content: "";
            display: none;
        }

        @media screen and (max-width: 480px) {
            padding: 0;
        }

        @include e("update-time") {
            padding-left: 0;
        }
    }

    @include m("no-more-event") {
        padding-bottom: 0;

        &:last-child{
            border-image: linear-gradient(#efefef 10px, #fafafa 10px) 1 100%;
        }

        &:first-child:last-child{
            border-image: linear-gradient(to bottom, #fafafa 10px, #efefef 10px, #efefef 10px, #fafafa 10px) 1 100%;
        }

        &:before{
            background-color: $timeline-update-time-color;
        }

        @include e("event-time") {
            padding-left: 0;
            //color: $timeline-update-time-color;
        }

        .timeline__event-time{
            @media screen and (max-width: 480px) {
                display: inline-block;
                padding-top: 0;
            }
        }

    }
}

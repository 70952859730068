.layout1{
    @include e("logo"){
        max-width: 160px;
        margin: auto;
        padding: 60px 0;
        img{
            max-width: 100%;
        }
    }

    @media screen and (max-width: 480px) {
        .list__item--event-details__event-detail.list__item--left-right{
            flex-wrap: wrap;
            .list__item--event-details__event-detail__content{
                width: 100%;
                margin-top: 4px;
            }
            .list__item--event-details__event-detail:not(:last-child){
                padding: 12px 0;
            }
        }
    }
}

.nav{
    padding: 0;
    margin: 0;
    list-style: none;

    .nav{
        position: absolute;
        top: 100%;
        left: 0;
        display: none;
        min-width: 195px;
        padding: 10px 0;

        .nav__item{
            display: block;
            padding: 5px 10px;
        }
    }
}

.nav__item{
    padding: 0 10px;
    display: inline-block;
    position: relative;

    i{
        margin-right: 8px;
        width: 15px;
        text-align: center;
    }

    &:hover .nav{
        display: block;
    }

    a{
        display: inline-block;
        padding: 0px 10px;
        opacity: 1;
        transition: opacity 0.25s;
        line-height: 1.7;
    }
    &:last-child a{
        padding-right: 0;
    }
    a:hover{
        opacity: 0.8;
    }
}

.nav__item--active{
    a{
        opacity: 1;
    }
}

.nav__item--has-subitem{
    position: relative;
}

.nav__subitem{
    display: none;
    background-color: #FFF;
    border: solid 1px #f2f2f2;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.15);
    border-radius: 3px;
}

.header .header__nav .nav.nav__subitem a{
    color: $service-color;
}

.list{
    padding: 0;
    margin: 0;
    list-style: none;

    & ul{
        padding-left: 25px;
        display: none;
        margin-top: -10px;
        margin-bottom: 10px;

        .list__item{
            padding: 10px 0;
        }
    }

    @include e("item"){
        padding: 22px 0;

        .tooltip-message{
            margin-left: 6px;
            cursor: pointer;
        }        

        @include e("title"){
            color: $text-color;
            font-weight: 400;
            margin: 0;
            margin-bottom: 0px;
            word-break: break-word;

            @media screen and (max-width: 480px) {
                margin-bottom: 0;
            }

            @include m("sm"){
                font-size: 16px;
                margin-bottom: 0px;
                @media screen and (max-width: 480px) {
                    font-size: 12px;
                }
            }

        }

        @include e("text") {
            color: $text-color;
            margin: 0;
            font-weight: 300;
            word-break: break-word;
        }

        @include e("meta"){
        	color: $meta-color;
            display: inline-block;
            font-weight: 300;
            margin-top: 4px;

            @media screen and (max-width: 480px) {
                font-size: 11px;
            }

            @include m("block"){
                display: block;
                margin: 2px 0;
                white-space: nowrap;
            }

            @include m("comma-seperate"){
                &:not(:last-child):after{
                    content: ", ";
                }
            }
        }

        @include m("left-right"){
            @include flex;
            align-items: center;
            justify-content: space-between;

            @media screen and (max-width: 480px) {
                flex-wrap: wrap;

                .left{
                    width: 100%;
                }

                .right{
                    width: 100%;
                    text-align: left !important;
                }
            }
        }

        @include m("seperator:not(:last-child)"){
            border-bottom: solid 1px $seperator-color;
        }

        @include m("has-child"){
            & > .list__item{
                cursor: pointer;

                .toggle-sub-services .fa-chevron-circle-right{
                    display: inline-block;
                }
                .toggle-sub-services .fa-chevron-circle-up{
                    display: none;
                }

                .toggle-sub-services i{
                    font-size: 17px;
                }
            }

            &.list__item--child-active > .list__item{
                .service__status-name{
                    display: none;
                }
                .toggle-sub-services .fa-chevron-circle-right{
                    display: none;
                }
                .toggle-sub-services .fa-chevron-circle-up{
                    display: inline-block;
                }
            }
        }

        @include m("collapsible"){
            cursor: pointer;
            position: relative;

            i.fa-chevron-right{
                flex: 0;
                width: 20px;
                margin-left: -18px;
                font-size: 10px;
                margin-right: 10px;
                transition: transform 0.25s;
                transform-origin: center;
                color: $description-grey;
                @media screen and (max-width: 480px) {
                    margin-right: 14px;
                    font-size: 8px;
                    margin-left: -4px;
                }
            }
            &.toggled {
                i.fa-chevron-right{
                    transform: rotate(90deg);
                }
            }
            .left{
                flex: 1;
            }

            @include e("parent-view"){

            }
            @include e("child-view"){
                margin-left: 0px;
                @media screen and (max-width: 480px) {
                    margin-left: 10px;
                }
            }
        }

        @include m("collapsible:hover"){
            &.toggled:after{
                transform: rotate(90deg);
            }
        }

        @include m("event-details"){
            margin-top: 20px;

            @include e("event-detail"){
                align-items: flex-start;
                border-radius: 6px;
                background-color: #FFF;
                padding: 15px;
                margin-bottom: 25px;
                box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.05), 1px 1px 5px rgba(0, 0, 0, 0.05);

                @include m("detail-page") {
                    box-shadow: none;

                    @media screen and (max-width: 480px) {
                        padding: 0;
                    }
                }

                @include e("content"){
                    width: 100%;
                }
            }

            @include e("event-detail:not(:last-child)"){
                //padding: 10px 0;
                //padding-top: 0;
            }

            @include e("event-detail:first-child"){
                //padding-top: 0px;
            }
        }

        @include m("announcement") {
            @include e("content") {
                width: 100%;
                color: $header-grey;
                font-size: 16px;
                font-weight: 300;
                p{
                    margin: 0;
                }
            }
        }
    }

}

.error{
    padding: 40px;

    @include m("fullscreen") {
        text-align: center;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $meta-color;
    }

    @include e("code") {
        font-size: 200px;
        font-weight: 300;
        margin: 0;
    }

    @include e("title") {
        font-size: 40px;
        font-weight: 300;
        margin: 0;
    }
}

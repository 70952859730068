.tag{
    background: $tag-background;
    color: $meta-color;
    padding: 5px 10px;
    display: inline-block;
    border-radius: 10px;
    margin-bottom: 4px;

    & + & {
        margin-left: 2px;
    }
}

.footer{

    background-color: $footer-color;
    height: 60px;
    line-height: 60px;
    color: $footer-text-color;
    font-weight: 300;
    text-align: right;

    @include e("powered-by"){
        font-size: 14px;
    }

}

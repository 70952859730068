*{
    //font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
}

.wf-loading{
    font-family: 'Roboto', sans-serif;
    visibility: hidden;
}
.wf-active{
    visibility: visible;
}

body,
html{
    background-color: #FFF;
    margin: 0;
    padding: 0;
}

html {
   height: 100%;
}

body {
   height: 100%;
   color: $text-color;
   background-color: $body-gradient-end;
}

.body-content-wrapper{
    min-height: 100%;
    margin-bottom: -60px;
    background-color: $body-gradient-end;
    &:after{
        content: " ";
        display: block;
        height: 60px;
    }
}

.bg-gradient{
    background-image: linear-gradient($body-gradient-start, $body-gradient-end);
}

b{
    font-weight: 600;
}

a{
    color: inherit;
    text-decoration: none;
}

a.back{
    font-size: 16px;
    display: inline-block;
    i{
        font-size: 14px;
        padding-right: 4px;
        vertical-align: baseline;
    }
}

.container{
    width: 90%;
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;
}

.text-align-right{
    text-align: right;
}

@mixin flex() {
    -js-display: flex;
    display: flex;
}

.font{
    @include m("largest") {
        font-size: 24px;
    }

    @include m("large") {
        font-size: 20px;
    }

    @include m("normal") {

    }

    @include m("small") {
        font-size: 14px;
    }

    @include m("smallest") {
        font-size: 13px;
    }

}

.font-weight{
    @include m("normal") {
        font-weight: 300;
    }
    @include m("heavy") {
        font-weight: 400;
    }
    @include m("heaviest") {
        font-weight: 500;
    }
}

@for $i from 1 through 20 {
   .m-#{$i} { margin: 1px * $i !important; }
   .m-t-#{$i} { margin-top: 1px * $i !important; }
   .m-l-#{$i} { margin-left: 1px * $i !important; }
   .m-r-#{$i} { margin-right: 1px * $i !important; }
   .m-b-#{$i} { margin-bottom: 1px * $i !important; }
}

.part{
    padding-top: 45px;
    padding-bottom: 45px;

    &:nth-child(2){
        padding-top: 55px;
    }

    &:nth-child(2),
    &:nth-child(3){
        background-color: #FFF;
    }

    &:nth-child(4){
        background-image: linear-gradient($body-gradient-start, $body-gradient-end);
    }


    @include e("notices") {
        padding-top: 20px;
    }

    @include e("overall-status") {
        // padding: 60px 0;
        // background-color: #FFF;
        // padding-bottom: 10px;
        padding-bottom: 20px;

        p{
            color: #696a69;
            font-weight: 300;
        }

        @media screen and (max-width: 480px) {
            padding: 45px 0;
            padding-bottom: 10px;
            padding-top: 20px;
        }
    }

    @include e("about") {
        padding: 20px 0;
        padding-bottom: 10px;
        // background-color: #FFF;

        p{
            color: #696a69;
            font-weight: 300;
            font-size: 17px;
            line-height: 1.4;
        }

        @media screen and (max-width: 480px) {
            padding: 45px 0;
            padding-bottom: 10px;
            padding-top: 20px;
        }
    }

    @include e("service") {
        padding: 45px 0;
        // background-image: linear-gradient($body-gradient-start, $body-gradient-end);

        @media screen and (max-width: 480px) {
            padding: 15px 0;
            padding-top: 20px;
        }

        .part + & {
            // padding: 20px 0;
            // padding-bottom: 45px;
        }

        .part.part__overall-status + & {
            padding-top: 40px;
        }
    }

    @include e("incidents"){
        padding-top: 20px;

        & + &{
            //padding-top: 0;
        }
    }

    @include e("event-historys") {
        padding-top: 70px;
        background-image: linear-gradient($body-gradient-start 0px, $body-gradient-end 400px);

        @media screen and (max-width: 480px) {
            padding-top: 20px;
        }
    }

    @include e("event-detail") {
        padding-top: 70px;
        padding-bottom: 70px;
        background-image: linear-gradient($body-gradient-start 0px, $body-gradient-end 150px);

        @media screen and (max-width: 480px) {
            padding-top: 20px;
        }
    }

    @include e("empty") {
        padding-top: 60px;
        text-align: center;
        .block-title{
            color: $footer-text-color;
        }
    }
}

.flex{
    @include flex();
    @include m("space-between") {
        justify-content: space-between;
    }
}

.sweet-alert{
    button{
        box-shadow: none !important;
    }
}

.services{
    //margin-top: 70px;
}

.service{

    @include e("name") {
        font-size: 16px;
        color: $service-color;
    }

    @include e("status-name") {
        font-size: 14px;
    }

    .list__item--left-right{
        @media screen and (max-width: 480px) {
            display: inherit;
            .service__name{
                margin-top: 2px;
            }
        }
    }

}

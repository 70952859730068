.block{

    border-radius: 6px;
    //background-color: #FFF;
    //box-shadow: 0 2px 3px rgba(0,0,0,0.1);
    //border: 1px solid $line-white;
    //padding: 10px 20px;
    //margin-bottom: 30px;
    //padding-bottom: 10px;

    @media screen and (max-width: 480px) {

    }

    @include e("header") {
        margin-bottom: 10px;

        @include e("title") {
            font-size: 12px;
        	letter-spacing: 1.1px;
        	color: $header-grey;
            text-transform: uppercase;
        }
    }

    @include e("content") {

    }

}

.block-footer{

    text-align: center;

    //@include flex();
    //justify-content: flex-start;
    //padding-bottom: 20px;

    @include e("link") {
        a{
            color: $boring-green;
            text-decoration: none;
            font-size: 16px;

            i{
                vertical-align: middle;
                font-size: 14px;
            }
        }
    }

    .block + &{
        margin-bottom: 30px;
    }

}

.block-title{
    color: $text-color;
    margin-bottom: 20px;
    font-weight: 400;
    padding-top: 20px;
    margin-top: 0;

    @include e("title") {

    }

}

.block-subtitle{
    color: $text-color;
    padding-top: 0px;

    @include e("title") {
        font-size: 18px;
    }

}

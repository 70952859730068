.detail-block{
    border-radius: 6px;
    background-color: #FFF;
    padding: 0 40px;
    box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.05), 1px 1px 5px rgba(0, 0, 0, 0.05);

    @media screen and (max-width: 480px) {
        padding: 0 5%;
    }

    @include e("header") {
        text-align: center;
        padding: 40px 0;
        border-bottom: 2px solid #F4F4F4;
    }

    @include e("title") {
        font-size: 36px;
        font-weight: 500;
        color: $text-color;
        margin: 0;
        margin-bottom: 15px;
    }

    @include e("event-time") {
        color: $timeline-event-time-color;
        font-size: 18px;
            margin-bottom: 15px;
    }

    @include e("affected-service") {
        color: #838383;
        font-size: 14px;
    }

    @include e("content") {
        padding: 40px 0;
        line-height: 1.5;

        p{
            font-weight: 300;
            font-size: 17px;
        }
    }
}

.label{
    border-radius: 4px;
    padding: 8px 0px;
    display: inline-block;
    background-color: $boring-green;
    color: #FFF;
    text-align: center;
    font-size: 14px;

    @media screen and (max-width: 480px) {
        font-size: 12px;
        padding: 4px;
    }

    @include m("sm") {
        padding: 3px 8px;
        font-size: 12px;

        @media screen and (max-width: 480px) {
            font-size: 10px;
            padding: 3px 5px;
        }
    }

    @include m("fix-width") {
        min-width: 140px;
        @media screen and (max-width: 480px) {
            min-width: 10px;
        }
    }

    @include m("outline") {
        background-color: #FFF;
        border: 1px solid $description-grey;
        color: $description-grey;
    }
}

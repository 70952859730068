@mixin button($padding, $color, $font-size: 14px){
    padding: $padding;
    background-color: $color;
    color: #FFF;
    border-radius: 7px;
    display: inline-block;
    outline: none;
    font-size: $font-size;
    transition: background-color 0.25s;
    i{
        font-size: $font-size - 4px;
        padding-right: 4px;
    }
    &:hover{
        background-color: darken($color, 10);
    }
    &.hide{
        opacity: 0;
        visibility: hidden;
    }

}

@mixin button-outline($color){
    background-color: transparent;
    color: $color;
    border: 1px solid $color;
    &:hover{
        background-color: $color;
        color: #FFF;
    }
}

.button{
    cursor: pointer;

    @include button(10px 25px, $boring-green, 14px);

    @include m("outline") {
        @include button-outline($boring-green);
    }

    @include m("white") {
        color: #FFF;
        border-color: #FFF;
        &:hover{
            color: #FFF;
            border-color: #FFF;
            background-color: transparent;
            opacity: 0.8;         
        }
    }

    @include m("round") {
        border-radius: 1000px;
    }

    @include m("icon") {
        height: 50px;
        width: 50px;
        padding: 0;
        position: relative;
        box-shadow: 0 6px 13px 0 rgba(0,0,0,.23);
        border: 1px solid darken($boring-green, 10);
        i{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 28px;
            padding: 0;
        }
    }
}
